import { useAtom, atom } from 'jotai';

import type { Response$login$Status$200 } from '~/api/generated/client';

type UserDataType = Response$login$Status$200['application/json']['user'];

type UseAuthStoreType =
  | {
      isLoggedIn: true;
      userData: UserDataType;
      setUserData: (userData: UserDataType) => void;
    }
  | {
      isLoggedIn: false;
      userData: undefined;
      setUserData: (userData: UserDataType) => void;
    };

const userDataAtom = atom<UserDataType | undefined>(undefined);

export const useAuthStore = (): UseAuthStoreType => {
  const [userData, setUserData] = useAtom(userDataAtom);

  if (userData !== undefined) {
    return {
      isLoggedIn: true,
      userData,
      setUserData,
    };
  }

  return {
    isLoggedIn: false,
    userData: undefined,
    // 初期値からステート更新する場合を加味してsetUserDataはundefinedにせず使えるようにする
    setUserData,
  };
};
